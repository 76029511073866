import React, { useCallback, useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import Footer from '../../component/Footer/footer';
import Navbar from '../../component/Navbar/navbar';
import logo_light from '../../assets/images/logo-white.png';
import { useMutation } from '@apollo/client';
import { isEmailValid, isPasswordValid } from '../../utils/Validator';
import { UNAUTHORIZED_NAMING } from '../../constants';
import { CREATE_USER_MUTATION } from '../../graphql/mutations/user';
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";


export default function AuthSignup() {

    const params = useParams()

    const [registerError, setRegisterError] = useState('')
    const [badNaming, setBadNaming] = useState({})
    const [registerErrorCase, setRegisterErrorCase] = useState()
    const [badEmailText, setBadEmailText] = useState()
    const [acceptCGU, setAcceptCGU] = React.useState(false)
    const [createUser, { loading, error }] = useMutation(CREATE_USER_MUTATION);
    const [showPwd, setShowPwd] = useState(false)
    const [referralCode, setReferralCode] = useState(params?.code);


    const [user, setUser] = useState({
        nickName: '',
        email: '',
        passwordHash: ''
    })

    const handleCGUChange = (e) => {
        setAcceptCGU(!acceptCGU)
    }
    const handleRegisterClick = useCallback((e) => {
        e.preventDefault();
        createUser({
            variables: {
                nickName: user.nickName?.trim(),
                email: user.email,
                password: user.passwordHash,
                referralCode: referralCode
            }
        }).then(() => {
            window.location.href = process.env.REACT_APP_ENDPOINT
        })
    }, [createUser, referralCode, user.email, user.nickName, user.passwordHash])

    const handleReferralChange = useCallback((event) => {
        setReferralCode(event.target.value)
    }, [])

    const handleInputChange = (event) => {
        const inputName = event.target.name
        const inputValue = event.target.value
        setUser({ ...user, [inputName]: inputValue })

        const checkNaming = inputName === 'nickName'

        //check for unAuthorized naming
        if (checkNaming && UNAUTHORIZED_NAMING.filter(n => inputValue.toLowerCase()?.includes(n))?.length) {
            setBadNaming({ ...badNaming, [inputName]: true })
        }
        else if (inputValue?.length === 1) {
            setBadNaming({ ...badNaming, [inputName]: true })
        }
        else if (checkNaming) {
            setBadNaming({ ...badNaming, [inputName]: false })
        }
    }

    const handleInputBlur = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value?.trim() })
    }

    const validForm = () => {
        if (user.email && user.passwordHash && user.nickName) { // && acceptCGU
            return isEmailValid(user.email) && isPasswordValid(user.passwordHash)
                && acceptCGU && user?.nickName?.length > 1
        }
        return false
    }

    const handleEmailBlur = () => {
        const _email = user.email?.trim()
        setUser({ ...user, email: _email })

        if (Number(_email?.length) > 0 && !isEmailValid(_email ?? '')) {
            setBadEmailText('Bad email')
        }
        else {
            setBadEmailText(undefined)
        }
    }

    useEffect(() => {
        //register errors
        if (error) {
            if (error.message?.includes('nickname')) {
                setRegisterErrorCase('nickName')
            }
            else {
                setRegisterErrorCase('email')
            }
            setRegisterError(error.message)
        }
    }, [error])

    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-20 bg-black bg-center bg-no-repeat bg-cover bg-[url('../../assets/images/bg3.png')]">
                <div className="absolute inset-0"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-6">
                        <h3 className="text-4xl md:leading-normal tracking-wide leading-normal font-medium text-white">Sign up to join the community</h3>
                        <p className='text-slate-300 mt-2 text-2xl' style={{ maxWidth: 420, margin: "0 auto" }}>Be part of the next big thing.</p>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Home</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl" /></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Sign up</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-12 py-16">

                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

                        <div className="lg:col-span-12 md:col-span-12">
                            <div className="lg:ms-5">
                                <div className="bg-secondary rounded-2xl py-6 px-12" style={{ maxWidth: 480, margin: "0 auto" }}>
                                    <div>
                                        <img src={logo_light} style={{ width: 120, margin: "0 auto", marginBottom: 34 }} alt="" />
                                    </div>
                                    <h3 className="mb-3 text-2xl text-blue-500 leading-normal font-medium">Create an Account</h3>

                                    <p className='text-slate-300 mb-10'>Join TheWide for free and start connecting with developers worldwide. No credit card required.</p>

                                    <form action="auth-signup-success" className="text-start">
                                        <div className="grid grid-cols-1">
                                            <div>
                                                <label className="font-semibold" htmlFor="RegisterName">Nickname:</label>
                                                <input
                                                    id="RegisterName"
                                                    name="nickName"
                                                    type="text"
                                                    value={user.nickName}
                                                    onBlur={handleInputBlur}
                                                    onChange={handleInputChange}
                                                    className="form-input mt-3 w-full py-2 px-3 h-10 bg-black-0 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-blue-500 focus:ring-0"
                                                    placeholder="DigitalNomad" />
                                                <div className='error'>
                                                    <span className='error_message'>
                                                        {
                                                            (registerError && registerErrorCase === "nickName") && !badNaming['nickName'] ? registerError : null
                                                        }
                                                        {
                                                            !registerError && badNaming['nickName'] ? "This nickName is not authorized" : null
                                                        }
                                                    </span>
                                                </div>
                                            </div>

                                            <div>
                                                <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
                                                <input
                                                    id="LoginEmail"
                                                    name="email"
                                                    value={user.email}
                                                    onBlur={handleEmailBlur}
                                                    onChange={handleInputChange}
                                                    type="email"
                                                    className="form-input mt-3 w-full py-2 px-3 h-10 bg-black-0 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-blue-500 focus:ring-0"
                                                    placeholder="name@example.com" />
                                                <div className='error'>
                                                    <span className='error_message'>
                                                        {
                                                            (registerError && registerErrorCase === "email" && !Boolean(badEmailText)) ? registerError : null
                                                        }
                                                        {
                                                            Boolean(badEmailText) ? badEmailText : null
                                                        }
                                                    </span>
                                                </div>
                                            </div>

                                            <div>
                                                <label className="font-semibold" htmlFor="LoginPassword">Password:</label>
                                                <div style={{ position: "relative" }}>
                                                    <input
                                                        id="LoginPassword"
                                                        name="passwordHash"
                                                        type={showPwd ? 'text' : 'password'}
                                                        value={user.passwordHash}
                                                        onChange={handleInputChange}
                                                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-black-0 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-blue-500 focus:ring-0"
                                                        placeholder="Password" />

                                                    <div className='showPwd' onClick={() => setShowPwd(!showPwd)}>
                                                        {
                                                            !showPwd ? <FaRegEye /> : <FaRegEyeSlash />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='error'>
                                                    <span className='error_message'>
                                                        {
                                                            user?.passwordHash?.length > 0 && user?.passwordHash?.length < 6 && "At least 6 characters."
                                                        }
                                                    </span>
                                                </div>
                                            </div>

                                            <div>
                                                <label className="font-semibold" htmlFor="LoginPassword">Referral code (optional):</label>
                                                <div style={{ position: "relative" }}>
                                                    <input
                                                        id="referralCode"
                                                        name="referralCode"
                                                        type={'text'}
                                                        value={referralCode}
                                                        onChange={handleReferralChange}
                                                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-black-0 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-blue-500 focus:ring-0"
                                                        placeholder="ABCdEFGHI=" />
                                                </div>
                                                <div className='error'>
                                                    <span className='error_message'>
                                                        {
                                                            user?.passwordHash?.length > 0 && user?.passwordHash?.length < 6 && "At least 6 characters."
                                                        }
                                                    </span>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="flex items-center w-full mb-0">
                                                    <input
                                                        id="AcceptT&C"
                                                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                                                        name="rememberMe"
                                                        type="checkbox"
                                                        checked={acceptCGU}
                                                        onChange={handleCGUChange}
                                                    />
                                                    <label className="form-check-label text-slate-400" htmlFor="AcceptT&C">I Accept <Link className="text-blue-500" to="https://storage.googleapis.com/thewide-static/privacy-policy.html" target="_blank">Terms And Condition</Link></label>
                                                </div>
                                            </div>

                                            <input
                                                type="submit"
                                                disabled={loading || !validForm() || badNaming['nickName']}
                                                onClick={handleRegisterClick}
                                                className="mt-7 mb-4 hover py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-blue-500 text-white rounded-2xl w-full"
                                                value="Register" />

                                            <div className="text-center">
                                                <span className="text-slate-400 me-2">Already have an account ? </span> <Link to="/login" className="text-black dark:text-white font-bold inline-block">Sign in</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
